<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New Restaurant Support Links</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputCategory" class="col-sm-2 col-form-label">Category <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select class="form-control" v-model="dataRequest.supplierId" @change="validationRule()">
										<option value="">Choose Category</option>
										<option v-for="category in categoryList" :value="category.id">{{category.title}}</option>
									</select>
									<small class="text-danger">{{validationData.supplierId}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputtitle" class="col-sm-2 col-form-label">Title <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.title" @blur="validationRule()"
										class="form-control" id="inputtitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDescription" class="col-sm-2 col-form-label">Description <span class="text-danger">*</span></label>
								<div class="col-sm-10">
                                    <textarea class="form-control" id="inputDescription" v-model="dataRequest.description" @blur="validationRule()"></textarea>
									<small class="text-danger">{{validationData.description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label">Address</label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.address" @blur="validationRule()"
										class="form-control" id="inputAddress">
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">Phone</label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputWebsite" class="col-sm-2 col-form-label">Website</label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.website" @blur="validationRule()"
										class="form-control" id="inputWebsite">
									<small class="text-danger">{{validationData.website}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputViber" class="col-sm-2 col-form-label">Viber</label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.viber" @blur="validationRule()"
										class="form-control" id="inputViber">
									<small class="text-danger">{{validationData.viber}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputWhatsapp" class="col-sm-2 col-form-label">Whatsapp</label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.whatsapp" @blur="validationRule()"
										class="form-control" id="inputWhatsapp">
									<small class="text-danger">{{validationData.whatsapp}}</small>
								</div>
							</div>


							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'clientCreate',
		metaInfo: {
            title: "Clent Create",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				categoryList: [],
				dataRequest: {
					supplierId: "",
					title: "",
					description: "",
					address: "",
					phone: "",
					website: "",
					viber: "",
					whatsapp: ""
				},
				validationData: {
					supplierId: "",
					title: "",
					description: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				supplierServiceStoreAction: 'supplierServiceStoreAction',
				supplierServiceDetailAction: 'supplierServiceDetailAction'
			}),
			async getFields(){
				let option = {
					type: "store"
				}
				await this.supplierServiceDetailAction({
					...option
				}).then(res => {
					this.categoryList = res.data.supplier
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('supplierId', this.dataRequest.supplierId); 
				formData.append('title', this.dataRequest.title); 
				formData.append('description', this.dataRequest.description); 
				formData.append('address', this.dataRequest.address); 
				formData.append('phone', this.dataRequest.phone); 
				formData.append('website', this.dataRequest.website); 
				formData.append('viber', this.dataRequest.viber); 
				formData.append('whatsapp', this.dataRequest.whatsapp); 

				await this.supplierServiceStoreAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'service-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', title: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.dataRequest.supplierId == "") {
					this.validationData.supplierId = "The supplier category field is required."
					isValidation.push(true)
				} else {
					this.validationData.supplierId = ""
					isValidation.push(false)
				}
				if (this.dataRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}
				if (this.dataRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}
				
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>